import type { LoaderFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import type { FeaturedThreadTags } from "~/utils/thread";
import { Outlet, useLoaderData } from "@remix-run/react";
import FeaturedTags from "~/components/threads/FeaturedTags";
import type { TokenPrices } from "~/utils/coingecko";
import { SearchBarInitiator } from "~/components/SearchBarInitiator";
import { leocache } from "~/utils/leocache";
import { SubscribeToPremium } from "./threads";
import React from "react";

export const loader: LoaderFunction = async () => {
  const [tags] = await Promise.all([leocache.trendingTags]);
  return json(
    { tags },
    {
      headers: { "Cache-Control": "public, max-age=60" }
    }
  );
};

interface ThreadsData {
  tags: FeaturedThreadTags;
  tokenPrices: TokenPrices;
  scotDenom: number;
}

export default function Threads() {
  const { tags, tokenPrices, scotDenom } = useLoaderData() as ThreadsData;

  return (
    <React.Fragment>
      <main className="flex-1 pc:flex-none w-full pc:w-8/12 border-0 tbl:border-x border-pri dark:border-pri-d shrink-0">
        <Outlet />
      </main>
      <aside className="hidden tbl:block w-full max-w-[20rem]">
        <div className="sticky top-0 p-3">
          <SearchBarInitiator />
          <SubscribeToPremium />
          <FeaturedTags tags={tags} />
        </div>
      </aside>
    </React.Fragment>
  );
}
